<template>
  <div class="amap-wrapper">
    <!-- lang="zh_en" -->
    <el-amap
      class="amap-box"
      :style="{ width: width, height: height }"
      mapStyle="amap://styles/normal"
      :vid="'amap-vue-search'"
      ref="map"
      :amap-manager="amapManager"
      :zoom="zoom"
      :plugin="plugin"
      :events="events"
    >
      <el-amap-marker
        v-for="(marker, index) in markers"
        :vid="index"
        :position="[marker.lng, marker.lat]"
        :key="index"
        :events="marker.events"
        :content="marker.content"

      >
        <div class="cus-marker" @click="handleMarker(marker)">
          <img class="item-img" src="~@/assets/images/icon_11.png" />
        </div>
      </el-amap-marker>
      <el-amap-info-window v-if="markerInfo" :position="markerInfo.lngLat" :visible="markerInfo.visible" :offset="markerInfo.offset">
        <div class="amap-list">
          <div class="item">{{ markerInfo.cityName }}：{{ markerInfo.devAll }}个</div>
        </div>
      </el-amap-info-window>
    </el-amap>
  </div>
</template>

  <script>
  import { AMapManager } from 'vue-amap'
  const amapManager = new AMapManager()
  export default {
    name: 'MapG',
    props: {
      width: {
        type: String || Number,
        default: '100%'
      },
      height: {
        type: String || Number,
        default: '500px'
      },
      isSearch: {
        type: Boolean,
        default: true
      },
      province: {
        type: String,
        default: ''
      },
      city: {
        type: String,
        default: ''
      },
      area: {
        type: String,
        default: ''
      },
      address: {
        type: String,
        default: ''
      },
      lat: {
        type: [String, Number],
        default: null
      },
      lng: {
        type: [String, Number],
        default: null
      },
      list: {
        type: Array,
        default: () => []
      }
    },
    data() {
      const self = this
      return {
        searchName: null,
        amapManager,
        zoom: 5,
        center: [116.396304, 39.909847],
        events: {
          // init(o) {
          //   setTimeout(() => {
          //     // console.log(self.markerRefs);
          //     let cluster = new AMap.MarkerClusterer(o, self.markerRefs,{
          //       gridSize: 80,
          //       renderCluserMarker: self._renderCluserMarker
          //     });
          //     // console.log(cluster, '98>>>>>>>>>>>>>>>>>');
          //   }, 1000);
          // },
          click: (e) => {
            console.log('----click----', e)
            const that = this
            that.markers.forEach(window => {
              window.visible = false // 关闭窗体
            })
          }
        },
        markerRefs: [],
        markers: [],
        searchOption: {
          city: '',
          citylimit: false
        },
        plugin: [],
        markerInfo: null,
        infoEvents: {
          close () {
            console.log('窗体关闭')
          }
        }
      }
    },
    watch: {
      list: {
        handler(value) {
          console.log(value, '998>>>>>>>>')
          // this.markers = [[108.939621, 34.343147],[113.370643, 22.938827]]
          this.markers = []
          value.forEach(item => {
            this.markers.push({
              ...item,
              visible: false,
              offset: [0, -20], // 窗体偏移
              content: '<div style="text-align:center; background-color: hsla(180, 100%, 50%, 0.7); height: 24px; width: 24px; border: 1px solid hsl(180, 100%, 40%); border-radius: 12px; box-shadow: hsl(180, 100%, 50%) 0px 0px 1px;"></div>',
              events: {
                init: (o) => {
                  // console.log(o, '119>>>>>>>>>');
                  // console.log(this);
                  this.markerRefs.push(o)
                }
              }
            })
          })
        }
      }
    },
    created() {
      window.onload = function() {
        document.getElementsByClassName('amap-logo')[0].remove()
        document.getElementsByClassName('amap-copyright')[0].remove()
      }
    },
    methods: {
      handleMarker(marker) {
        console.log('----marker----', marker)
        const that = this
        that.markers.forEach(window => {
          window.visible = false // 关闭窗体
        })
        that.$nextTick(() => {
          marker.visible = true// 点击点坐标，出现信息窗体
        })
        this.markerInfo = marker
      },
      init(lng, lat, label) {
        console.log('-----init-----', lng, lat, label)
        if (lng && lat) {
          this.center = [lng, lat]
          this.markers = [
            { lng: this.center[0], lat: this.center[1], name: label }
          ]
        } else {
          this.center = [116.396304, 39.909847]
        }
      },
      _renderCluserMarker(context) {
        const count = this.markers.length

        const factor = Math.pow(context.count / count, 1 / 18)
        const div = document.createElement('div')
        const Hue = 180 - factor * 180
        const bgColor = 'hsla(' + Hue + ',100%,50%,0.7)'
        const fontColor = 'hsla(' + Hue + ',100%,20%,1)'
        const borderColor = 'hsla(' + Hue + ',100%,40%,1)'
        const shadowColor = 'hsla(' + Hue + ',100%,50%,1)'
        div.style.backgroundColor = bgColor
        const size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20)
        div.style.width = div.style.height = size + 'px'
        div.style.border = 'solid 1px ' + borderColor
        div.style.borderRadius = size / 2 + 'px'
        div.style.boxShadow = '0 0 1px ' + shadowColor
        div.innerHTML = context.count
        div.style.lineHeight = size + 'px'
        div.style.color = fontColor
        div.style.fontSize = '14px'
        div.style.textAlign = 'center'
        context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2))
        context.marker.setContent(div)
      },
      geoCoder(positionArr) {
        // 获取地址经纬度和省市区
        var that = this
        // 这里通过高德 SDK 完成。
        // eslint-disable-next-line no-undef
        var geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: 'all'
        })
        geocoder.getAddress(positionArr, function (status, result) {
          console.log('----positionArr----', positionArr)
          if (status === 'complete' && result.info === 'OK') {
            if (result && result.regeocode) {
              const detailObj = {
                addressComponent: result.regeocode.addressComponent,
                detailedAddress: result.regeocode.formattedAddress,
                lng: positionArr[0],
                lat: positionArr[1]
              }
              that.markers.push({
                lng: positionArr[0],
                lat: positionArr[1],
                name: result.regeocode.formattedAddress
              })
              that.$emit('update:province', detailObj.addressComponent.province)
              that.$emit('update:city', detailObj.addressComponent.city || detailObj.addressComponent.province)
              that.$emit('update:area', detailObj.addressComponent.district)
              that.$emit('update:address', detailObj.detailedAddress)
              that.$emit('update:lat', detailObj.lat)
              that.$emit('update:lng', detailObj.lng)
              that.$emit('getInfo', detailObj)
            }
          }
        })
      },
      onSearchResult(pois) {
        console.log('-----pois-----', pois)
        let latSum = 0
        let lngSum = 0
        if (pois.length > 0) {
          pois.forEach((poi) => {
            const { lng, lat } = poi
            lngSum += lng
            latSum += lat
            // this.markers.push([poi.lng, poi.lat]);
          })
          this.markers = [pois[0]]
          this.geoCoder([this.markers[0].lng, this.markers[0].lat])
          const center = {
            lng: lngSum / pois.length,
            lat: latSum / pois.length
          }
          this.center = [center.lng, center.lat]
        }
      }
    }
  }
  </script>
  <style lang="less" scoped>
  ::v-deep{
    .amap-logo{
      display: none !important;
    }
    .amap-copyright{
      visibility: hidden !important;
    }
  }
  .item-img{
    width: 30px;
    height: 30px;
  }
  .cus-marker{
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      position: absolute;
    }
  }
  /deep/ .amap-info-content{

    padding-right: 30px !important;
    padding-bottom: 8px !important;
    .amap-info-close{
      right: 5px !important;
    }
  }
  .amap-list{
    .item{
      padding: 5px 0;
      color: #030A25;
      font-size: 16px;
    }
  }
  </style>
