<template>
  <page-header-wrapper>
    <div class="box">
      <div class="box1">
        <div class="data-profiling">
          <div class="title">数据概况 <div class="span">实时数据：{{ dateInfo }} <img src="@/assets/images/icon_9.png" @click="getInfo()"></div></div>
          <div class="list">
            <div class="item">
              <img src="@/assets/images/icon_1.png" style="cursor: pointer;" @click="goRouter('/device/device4G')">
              <div class="tex">
                <div class="span1">设备数量</div>
                <div class="span2">{{ gkInfo.devNum ? gkInfo.devNum : 0 }}</div>
              </div>
            </div>
            <div class="item">
              <img src="@/assets/images/icon_2.png" style="cursor: pointer;" @click="goRouter('/appUser')">
              <div class="tex">
                <div class="span1">用户总数</div>
                <div class="span2">{{ gkInfo.userAllNUm ? gkInfo.userAllNUm : 0 }}</div>
              </div>
            </div>
            <div class="item">
              <img src="@/assets/images/icon_3.png" >
              <div class="tex">
                <div class="span1">总营收</div>
                <div class="span2">{{ gkInfo.allYs ? gkInfo.allYs : 0 }}</div>
              </div>
            </div>
            <!-- <div class="item">
              <img src="@/assets/images/icon_4.png" >
              <div class="tex">
                <div class="span1">短信余量</div>
                <div class="span2">1651</div>
              </div>
            </div> -->
            <div class="item">
              <img src="@/assets/images/icon_5.png" >
              <div class="tex">
                <div class="span1">当月抄收数量</div>
                <div class="span2">{{ gkInfo.nowMonthNum ? gkInfo.nowMonthNum : 0 }}</div>
              </div>
            </div>
            <div class="item">
              <img src="@/assets/images/icon_6.png" >
              <div class="tex">
                <div class="span1">本月营收</div>
                <div class="span2">{{ gkInfo.monthYs ? gkInfo.monthYs : 0 }}</div>
              </div>
            </div>
          </div>
          <div class="title title2">数据趋势图 <a-range-picker style="width: 260px;" @change="onChange1"><a-icon slot="suffixIcon" type="calendar" /></a-range-picker></div>
          <line_1 height="340px" :dataInfo="echartsData1" v-if="echartsData1.id"/>
        </div>
        <div class="device-distribution">
          <div class="title">设备分布
            <!-- <div class="span">50% <img src="@/assets/images/icon_10.png" ></div> -->
          </div>
          <div class="list">
            <div class="item" v-for="(item,key) in mapList" :key="key">{{ item.cityName }}:{{ item.devAll }}个</div>
          </div>
          <a-map height="370px" :list="mapList"></a-map>
        </div>
      </div>
      <div class="box2">
        <div class="left">
          <div class="title">待处理项</div>
          <perfect_1 width="240px" height="240px" :dataInfo="echartsData2" v-if="echartsData2.id"/>
          <div class="btn" @click.stop="goRouter('/device/deviceFault')">去处理</div>
        </div>
        <div class="right">
          <a-radio-group v-model="type" style="margin-bottom: 16px">
            <a-radio-button :value="0">全部</a-radio-button>
            <a-radio-button :value="1">设备报警</a-radio-button>
            <a-radio-button :value="2">业务报警</a-radio-button>
          </a-radio-group>
          <div class="tab-list">
            <div class="item g">
              <div class="span1" style="cursor: pointer;" @click.stop="goRouter('/device/deviceFault')">去处理></div>
              <div class="span2">异常报警：{{ pendingInfo.ycAllNum ? pendingInfo.ycAllNum : 0 }}</div>
            </div>
            <div class="item y">
              <div class="span1" style="cursor: pointer;" @click.stop="goRouter('/device/deviceFault')">去处理></div>
              <div class="span2">故障报警：{{ pendingInfo.gzAllNum ? pendingInfo.gzAllNum : 0 }}</div>
            </div>
            <div class="item b">
              <div class="span1" style="cursor: pointer;" @click.stop="goRouter('/device/deviceFault')">去查看></div>
              <div class="span2">业务报警：{{ pendingInfo.ywAllNum ? pendingInfo.ywAllNum : 0 }}</div>
            </div>
          </div>
          <vue-seamless-scroll :data="pageInfo" class="warp" :classOption="{step: 1}">
            <div class="list">
              <div class="item" v-for="(item,key) in pageInfo" :key="key">
                <div class="span1" style="color: #FF7F00;" v-if="item.type == 0">设备报警</div>
                <div class="span1" style="color: #007AFF;" v-else-if="item.type == 1">业务报警</div>
                <div class="span1" style="color: #049504;" v-else-if="item.type == 2">异常报警</div>
                <div class="span2">{{ item.num }}</div>
                <div class="span3">{{ item.remark }}</div>
                <div class="span4"><div class="btn" @click.stop="goRouter('/device/deviceFault')">去处理</div></div>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
      <div class="box3">
        <div class="item">
          <div class="title">在线数</div>
          <div class="num">
            {{ devZxInfo.userPjZt }}
            <div class="span" style="color: #FF2C2C;" v-if="devZxInfo.type == 1">{{ devZxInfo.userPj }} <img src="@/assets/images/icon_7.png" ></div>
            <div class="span" style="color: #049504;" v-else-if="devZxInfo.type == 0">{{ devZxInfo.userPj }} <img src="@/assets/images/icon_8.png" ></div>
          </div>
          <line_2 height="190px" :dataInfo="echartsData3" v-if="echartsData3.id"/>
        </div>
        <div class="item">
          <div class="title">用户在线率</div>
          <div class="num">
            {{ devZxlInfo.userPjZt }}%
            <div class="span" style="color: #FF2C2C;" v-if="devZxlInfo.type == 1">{{ devZxlInfo.userPj }}% <img src="@/assets/images/icon_7.png" ></div>
            <div class="span" style="color: #049504;" v-else-if="devZxlInfo.type == 0">{{ devZxlInfo.userPj }}% <img src="@/assets/images/icon_8.png" ></div>
          </div>
          <bar_2 height="190px" :dataInfo="echartsData4" v-if="echartsData4.id"/>
        </div>
        <div class="item">
          <div class="title">支付笔数</div>
          <div class="num">
            {{ devPayNumInfo.payNumPjZt }}
            <div class="span" style="color: #FF2C2C;" v-if="devPayNumInfo.type == 1">{{ devPayNumInfo.payNumPj }} <img src="@/assets/images/icon_7.png" ></div>
            <div class="span" style="color: #049504;" v-else-if="devPayNumInfo.type == 0">{{ devPayNumInfo.payNumPj }} <img src="@/assets/images/icon_8.png" ></div>
          </div>
          <line_2 height="190px" :dataInfo="echartsData5" v-if="echartsData5.id"/>
        </div>
        <div class="item">
          <div class="title">平均单价</div>
          <div class="num">
            ￥{{ devPjdjInfo.moneyPjZt }}
            <div class="span" style="color: #FF2C2C;" v-if="devPjdjInfo.type == 1">￥{{ devPjdjInfo.moneyPj }} <img src="@/assets/images/icon_7.png" ></div>
            <div class="span" style="color: #049504;" v-else-if="devPjdjInfo.type == 0">￥{{ devPjdjInfo.moneyPj }} <img src="@/assets/images/icon_8.png" ></div>
          </div>
          <bar_2 height="190px" :dataInfo="echartsData6" v-if="echartsData6.id"/>
        </div>
      </div>
    </div>
  </page-header-wrapper>
</template>

<script>
import { PageHeaderWrapper } from '@/components/ProLayout'
import { message } from 'ant-design-vue'
import vueSeamlessScroll from 'vue-seamless-scroll'
import aMap from '@/components/AmapPoint/index'
import bar_2 from '@/components/echarts/bar/bar_2'
import line_1 from '@/components/echarts/line/line_1'
import line_2 from '@/components/echarts/line/line_2'
import perfect_1 from '@/components/echarts/perfect/perfect_1'
import { devFb, gk, page, pending, qst, devPayNum, devPjdj, devZx, devZxl } from '@/api/pages/home'

export default {
  name: 'Index',
  components: {
    PageHeaderWrapper,
    vueSeamlessScroll,
    aMap,
    bar_2,
    line_1,
    line_2,
    perfect_1
  },
  data () {
    return {
      dateInfo: '',
      type: 0,
      list: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      mapList: [],
      gkInfo: {
        allYs: 0,
        devNum: 0,
        monthYs: 0,
        nowMonthNum: 0,
        userAllNUm: 0
      },
      pageInfo: [],
      pendingInfo: {
        gzAllNum: 0,
        pendingAllNum: 0,
        ycAllNum: 0,
        ywAllNum: 0
      },
      beginTime: '',
      endTime: '',
      echartsData1: {},
      echartsData2: {},
      devZxInfo: {},
      echartsData3: {},
      devZxlInfo: {},
      echartsData4: {},
      devPayNumInfo: {},
      echartsData5: {},
      devPjdjInfo: {},
      echartsData6: {}
    }
  },
  computed: {
  },
  created () {
    this.getInfo()
  },
  mounted () {
  },
  methods: {
    goRouter(url) {
      this.$router.push({ path: url })
    },
    onChange1(date, dateString) {
      console.log(date, dateString)
    },
    getInfo() {
      const hide = message.loading('加载数据中...', 0)
      setTimeout(hide, 2500)

      const now = new Date()
      const year = now.getFullYear()
      const month = (now.getMonth() + 1) < 10 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1)
      const day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate()
      const hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours()
      const minute = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()
      const second = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds()
      this.dateInfo = `${year}-${month}-${day} ${hour}:${minute}:${second}`

      this.getDevFb()
      this.getGk()
      this.getPage()
      this.getPending()
      this.getQst()
      this.getDevPayNum()
      this.getDevPjdj()
      this.getDevZx()
      this.getDevZxl()
    },
    // 设备分布
    getDevFb () {
      const that = this
      devFb().then(response => {
        const data = response.data
        that.$nextTick(() => {
          const list = []
          data.map(item => {
            if (item.log && item.lat) {
              list.push({
                lngLat: [item.log, item.lat],
                lng: item.log,
                lat: item.lat,
                ...item
              })
            }
          })
          that.mapList = list
          that.$forceUpdate()
        })
      })
    },
    // 数据概况
    getGk () {
      gk().then(response => {
        this.gkInfo = response.data
      })
    },
    // 故障记录
    getPage () {
      page({ status: 0, pageNum: 1, pageSize: 999999999 }).then(response => {
        this.pageInfo = response.data.records
      })
    },
    // 待处理项总数量、异常报警数量、故障报警数量、业务报警数量
    getPending () {
      pending().then(response => {
        this.pendingInfo = response.data

        this.echartsData2 = {
          color: ['#FF2C2C'],
          id: 'echarts2',
          name: '待处理',
          number: response.data.pendingAllNum + '项',
          percentage: (response.data.pendingAllNum && response.data.pendingAllNum !== 0 && response.data.pendingAllNum !== '0') ? 30 : 0
        }
        this.$forceUpdate()
      })
    },
    // 数据趋势图
    getQst () {
      qst({ beginTime: this.beginTime, endTime: this.endTime }).then(response => {
        const data = response.data
        const dwlist = []
        const list1 = []
        const list2 = []
        const list3 = []
        data.map(item => {
          dwlist.push(item.name + '月')
          list1.push(item.waterAll ? item.waterAll : 0)
          list2.push(item.devAll ? item.devAll : 0)
          list3.push(item.moneyAll ? item.moneyAll : 0)
        })
        this.echartsData1 = {
          color: ['#FF7F00', '#007AFF', '#049504'],
          textStyle: {
            color: '#fff'
          },
          id: 'echarts1',
          dwlist: dwlist,
          line: [{
            name: '水使用量',
            type: 'line',
            showSymbol: false,
            data: list1
          }, {
            name: '设备总数',
            type: 'line',
            showSymbol: false,
            data: list2
          }, {
            name: '合计支付金额',
            type: 'line',
            showSymbol: false,
            data: list3
          }]
        }
        this.$forceUpdate()
      })
    },
    // 支付笔数
    getDevPayNum () {
      devPayNum().then(response => {
        const data = response.data
        this.devPayNumInfo = data
        const dwlist = []
        const list = []
        data.list.map(item => {
          dwlist.push(item.time)
          list.push(item.payNumAll)
        })
        this.echartsData5 = {
          color: ['#007AFF'],
          textStyle: {
            color: '#fff'
          },
          id: 'echarts5',
          dwlist: dwlist,
          line: [{
            name: '支付笔数',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              normal: {
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(0, 122, 255, .2)'
                },
                {
                  offset: 1,
                  color: 'rgba(120, 175, 234, 0)'
                }], false),
                shadowColor: 'rgba(0, 122, 255, .1)',
                shadowBlur: 5
              }
            },
            data: list
          }]
        }
        this.$forceUpdate()
      })
    },
    // 平均单价
    getDevPjdj () {
      devPjdj().then(response => {
        const data = response.data
        this.devPjdjInfo = data
        const dwlist = []
        const list = []
        console.log(data)
        data.list.map(item => {
          dwlist.push(item.time)
          list.push(item.moneyAll)
        })
        this.echartsData6 = {
          color: ['#007AFF', '#0EBF0E'],
          textStyle: {
            color: '#fff'
          },
          id: 'echarts6',
          dwlist: dwlist,
          line: [{
            name: '平均单价',
            type: 'bar',
            smooth: true,
            areaStyle: { opacity: 0.2 },
            data: list,
            itemStyle: {
              barBorderRadius: [7, 7, 0, 0]
            },
            showBackground: false,
            barWidth: 10 // 设置柱状图宽度为10像素
          }
          // {
          //   name: '历史单价',
          //   type: 'bar',
          //   smooth: true,
          //   areaStyle: { opacity: 0.2 },
          //   data: [152, 156, 141, 120, 160, 180, 150, 230, 220, 204, 10, 250],
          //   itemStyle: {
          //     barBorderRadius: [7, 7, 0, 0]
          //   },
          //   showBackground: false,
          //   barWidth: 10 // 设置柱状图宽度为10像素
          // }
          ]
        }
        this.$forceUpdate()
      })
    },
    // 在线数
    getDevZx () {
      devZx().then(response => {
        const data = response.data
        this.devZxInfo = data
        const dwlist = []
        const list = []
        data.list.map(item => {
          dwlist.push(item.time)
          list.push(item.userAll)
        })
        this.echartsData3 = {
          color: ['#007AFF'],
          textStyle: {
            color: '#fff'
          },
          id: 'echarts3',
          dwlist: dwlist,
          line: [{
            name: '在线数',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              normal: {
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(0, 122, 255, .2)'
                },
                {
                  offset: 1,
                  color: 'rgba(120, 175, 234, 0)'
                }], false),
                shadowColor: 'rgba(0, 122, 255, .1)',
                shadowBlur: 5
              }
            },
            data: list
          }]
        }
        this.$forceUpdate()
      })
    },
    // 在线率
    getDevZxl () {
      devZxl().then(response => {
        const data = response.data
        this.devZxlInfo = data
        const dwlist = []
        const list = []
        data.list.map(item => {
          dwlist.push(item.time)
          list.push(item.userAll)
        })
        this.echartsData4 = {
          color: ['#007AFF', '#0EBF0E'],
          textStyle: {
            color: '#fff'
          },
          id: 'echarts4',
          dwlist: dwlist,
          line: [{
            name: '用户在线率',
            type: 'bar',
            smooth: true,
            areaStyle: { opacity: 0.2 },
            data: list,
            itemStyle: {
              barBorderRadius: [7, 7, 0, 0]
            },
            showBackground: false,
            barWidth: 10 // 设置柱状图宽度为10像素
          }
          // , {
          //   name: '用户离线率',
          //   type: 'bar',
          //   smooth: true,
          //   areaStyle: { opacity: 0.2 },
          //   data: [152, 156, 141, 120, 160, 180, 150, 230, 220, 204, 10, 250],
          //   itemStyle: {
          //     barBorderRadius: [7, 7, 0, 0]
          //   },
          //   showBackground: false,
          //   barWidth: 10 // 设置柱状图宽度为10像素
          // }
          ]
        }
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index.less";
  .box{
    width: 100%;
    padding: 0 24px;
  }
  .box1{
    width: 100%;
    display: flex;
    margin-bottom: 26px;
    .title{
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 22px;
      color: #333333;
      .span{
        height: 28px;
        border-radius: 14px;
        background: rgba(0, 122, 255, 0.06);
        padding: 0 12px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #007AFF;
        margin-left: 18px;
        img{
          width: 14px;
          height: 14px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
    .data-profiling{
      width: 1075px;
      height: 500px;
      background: #ffffff;
      border-radius: 26px;
      margin-right: 26px;
      padding: 20px;
      position: relative;
      .title{
        justify-content: flex-start;
      }
      .list{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 28px 0;
        .item{
          // width: 16.6%;
          width: 20%;
          height: 52px;
          display: flex;
          align-items: center;
          img{
            flex-shrink: 0;
            width: 52px;
            height: 52px;
          }
          .tex{
            display: flex;
            flex-direction: column;
            padding: 0 16px;
            .span1{
              font-size: 12px;
              color: #666666;
            }
            .span2{
              font-size: 20px;
              color: #333333;
              font-weight: bold;
            }
          }
        }
      }
      .title2{
        width: calc(100% - 40px);
        justify-content: space-between;
        position: absolute;
        z-index: 10;
      }
    }
    .device-distribution{
      width: 500px;
      height: 500px;
      background: #ffffff;
      border-radius: 26px;
      padding: 20px;
      .title{
        justify-content: space-between;
      }
      .list{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 16px 0;
        .item{
          flex-grow: 1;
          font-size: 14px;
          color: #333333;
        }
      }
    }
  }
  .box2{
    width: 100%;
    height: 400px;
    background: #ffffff;
    border-radius: 26px;
    margin-bottom: 26px;
    padding: 20px;
    display: flex;
    .left{
      flex-shrink: 0;
      width: 370px;
      height: 100%;
      border-right: 1px solid rgba(112, 112, 112, .2);
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        width: 100%;
        font-size: 22px;
        color: #333333;
      }
      .btn{
        width: 85px;
        height: 36px;
        background: #FF2C2C;
        border-radius: 4px;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .right{
      flex-grow: 1;
      padding-left: 30px;
      .tab-list{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .item{
          width: 250px;
          height: 70px;
          border-radius: 6px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .span1{
            font-size: 12px;
            margin-bottom: 3px;
          }
          .span2{
            font-size: 18px;
            color: #333333;
          }
        }
        .g{
          background: rgba(108, 205, 60, .1);
          .span1{
            color: rgba(108, 205, 60, 1);
          }
        }
        .y{
          background: rgba(251, 212, 55, .1);
          .span1{
            color: rgba(251, 212, 55, 1);
          }
        }
        .b{
          background: rgba(0, 122, 255, .1);
          .span1{
            color: rgba(0, 122, 255, 1);
          }
        }
      }
      .warp {
        width: 100%;
        height: 180px;
        margin: 0 auto;
        overflow: hidden;
        color: #f0f5ff;
      }
      .list{
        width: 100%;
        .item{
          width: 100%;
          height: 46px;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #333333;
          .span1,.span2{
            flex-shrink: 0;
            width: 100px;
          }
          .span3{
            flex-grow: 1;
          }
          .span4{
            flex-shrink: 0;
          }
          .btn{
            width: 85px;
            height: 36px;
            background: rgba(220, 220, 220, .4);
            border-radius: 4px;
            color: #333333;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .box3{
    width: 100%;
    display: flex;
    .item{
      width: 25%;
      height: 330px;
      background: #ffffff;
      border-radius: 26px;
      margin-right: 26px;
      padding: 20px;
      &:last-child{
        margin-right: 0;
      }
      .title{
        font-size: 22px;
        color: #333333;
      }
      .num{
        font-size: 40px;
        color: #000000;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        .span{
          padding-bottom: 8px;
          font-weight: initial;
          font-size: 20px;
          margin-left: 6px;
          display: flex;
          align-items: center;
          img{
            width: 20px;
            height: 20px;
            margin-left: 3px;
          }
        }
      }
    }
  }
</style>
