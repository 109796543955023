import request from '@/utils/request'

// 首页 设备分布
export function devFb(query) {
    return request({
        url: '/wisdom/data/dev/fb',
        method: 'get',
        params: query
    })
}

// 首页 数据概况
export function gk(query) {
    return request({
        url: '/wisdom/data/gk',
        method: 'get',
        params: query
    })
}

// 首页 故障记录分页
export function page(query) {
    return request({
        url: '/wisdom/data/page',
        method: 'get',
        params: query
    })
}

// 首页 待处理项总数量、异常报警数量、故障报警数量、业务报警数量
export function pending(query) {
    return request({
        url: '/wisdom/data/pending',
        method: 'get',
        params: query
    })
}

// 首页 数据趋势图
export function qst(query) {
    return request({
        url: '/wisdom/data/qst',
        method: 'get',
        params: query
    })
}

// 首页 支付笔数
export function devPayNum(query) {
    return request({
        url: '/wisdom/dev/pay/num',
        method: 'get',
        params: query
    })
}

// 首页 平均单价
export function devPjdj(query) {
    return request({
        url: '/wisdom/dev/pjdj',
        method: 'get',
        params: query
    })
}

// 首页 在线数
export function devZx(query) {
    return request({
        url: '/wisdom/dev/zx',
        method: 'get',
        params: query
    })
}

// 首页 在线率
export function devZxl(query) {
    return request({
        url: '/wisdom/dev/zxl',
        method: 'get',
        params: query
    })
}
