<template>
  <div :id="dataInfo.id" :style="{ width: width, height: height }"></div>
</template>

  <script>
  export default {
    props: {
      dataInfo: {
        type: Object,
        default: () => {}
      },
      width: {
        type: String || Number,
        default: '100%'
      },
      height: {
        type: String || Number,
        default: '500px'
      }
    },
    data() {
      return {}
    },
    created() {
    },
    mounted() {
      this.getPie(this.dataInfo)
    },
    methods: {
      getPie(data) {
        const myChart = this.$echarts.init(document.getElementById(data.id))
        myChart.setOption({
            color: data.color,
            grid: {
                top: 10,
                left: 10,
                right: 10,
                bottom: 10,
                containLabel: true
            },
            tooltip: {
                trigger: 'axis'
            },
            series: [{
                color: data.color,
                type: 'gauge',
                startAngle: 90,
                endAngle: -270,
                pointer: {
                    show: false
                },
                progress: {
                    show: true,
                    overlap: false,
                    roundCap: true,
                    clip: false
                },
                axisLine: {
                    lineStyle: {
                        width: 14
                    }
                },
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                data: [
                    {
                        value: data.percentage,
                        name: data.name,
                        detail: {
                            offsetCenter: ['0%', '-20%']
                        }
                    }
                ],
                detail: {
                    width: 18,
                    height: 18,
                    fontSize: 18,
                    color: 'inherit',
                    formatter: data.number
                }
            }]
        })
      }
    }
  }
  </script>

  <style lang="less">
  .ebox{
    width: 100%;
    height: 200px;
  }
  </style>
