<template>
  <div>
    <div :id="dataInfo.id" :style="{ width: width, height: height }"></div>
  </div>
</template>
<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    },
    width: {
      type: String || Number,
      default: '100%'
    },
    height: {
      type: String || Number,
      default: '500px'
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.getLine(this.dataInfo)
  },
  methods: {
    getLine(data) {
      console.log('line2----', data)
      const myChart = this.$echarts.init(document.getElementById(data.id))
      if (data.dwlist.length === 0) { // 暂无数据
        myChart.setOption({
          title: {
            text: '暂无数据',
            x: 'center',
            y: 'center',
            textStyle: {
              fontSize: 14,
              color: '#333333',
              fontWeight: 'normal'
            }
          }
        })
      } else {
        myChart.setOption({
          color: data.color,
          // legend: {
          //   top: 5,
          //   left: 150,
          //   // icon: 'roundRect',
          //   textStyle: {
          //     color: '#333333'
          //   }
          // },
          dataZoom: [
            {
              type: 'inside', // slider表示有滑动块的，inside表示内置的
              show: true,
              backgroundColor: '#262626', // 组件的背景颜色
              height: 10,
              xAxisIndex: 0,
              bottom: 10,
              start: 0,
              end: 100,
              handleSize: 10,
              minSpan: 8, // 最小滑动距离
              handleIcon: '',
              showDetail: false,
              filterMode: 'filter',
              borderColor: '#262626', // 边框颜色
              fillerColor: '#182434', // 选中范围背景色
              backgroundColor: '#262626', // 背景颜色
              moveOnMouseMove: true,
              dataBackground: {
                lineStyle: {
                  color: 'red'
                },
                areaStyle: {
                  color: '#121212'
                }
              },
              selectedDataBackground: {
                lineStyle: {
                  color: '#green'
                },
                areaStyle: {
                  color: '#fff',
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                  shadowBlur: 10
                }
              },
              handleSize: 30,
              handleSize: '130%', // 手柄大小
              // 用于画手柄
              handleIcon:
                // "M0,0 v9.7h5 v-9.7h-5 Z", // 画一个长方形
                'path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5M36.9,35.8h-1.3z M27.8,35.8 h-1.3H27L27.8,35.8L27.8,35.8z', // 画一个圆形
              handleStyle: {
                color: '#999',
                global: true, // 缺省为 false
                shadowBlur: 6,
                shadowColor: 'rgba(123, 154, 204, 0.5)',
                shadowOffsetX: 0, // 阴影偏移x轴多少
                shadowOffsetY: 0 // 阴影偏移y轴多少
              }
            }
          ],
          tooltip: {
            trigger: 'axis'
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {
          //       type: 'png'
          //     }
          //   }
          // },
          grid: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            containLabel: true
          },
          xAxis: {
            type: 'category',
            // axisLabel: {
            //   interval: 0
            // },
            data: data.dwlist,
            boundaryGap: false,
            axisLabel: {// 修改坐标系字体颜色
              show: false,
              textStyle: {
                color: '#333333'
              }
            }
          },
          yAxis: {
            type: 'value',
            name: data.name,
            minInterval: 1,
            min: 0, // 最小是0
            max: function (value) { // 最大设定
              if (value.max < 6) {
                return 6
              } else {
                return value.max
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {// 修改坐标系字体颜色
              show: false,
              textStyle: {
                color: '#333333'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'none'
              }
            }
          },
          series: data.line
        })
      }
    }
  }
}
</script>
<style lang='scss'>
</style>
